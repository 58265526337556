function setupStripeForm() {
  var stripe = Stripe(process.env.STRIPE_ACCESS_KEY);
  // Create an instance of Elements.
  var elements = stripe.elements();
  // Custom styling can be passed to options when creating an Element.
  // (Note that this demo uses a wider set of styles than the guide below.)
  var style = {
    base: {
      color: '#000',
      fontFamily: '"Noto Sans JP", sans-serif',
      fontSize: '16px'
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    }
  };
  // Create an instance of the card Element.
  var card = elements.create('card', {style: style, hidePostalCode: true});
  // Add an instance of the card Element into the `card-element` <div>.
  card.mount('#card-element');
  $('#card-errors').css('display', 'none');
  // Handle real-time validation errors from the card Element.
  card.on('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });
  // Handle form submission.
  var form = document.getElementById('payment-form');
  form.addEventListener('submit', function(event) {
    // 既存の支払い方法での決済の場合は、そのまま実行する
    if ($('#pay_info_existing_source').prop('checked')) {
      form.submit();
      return;
    }
    event.preventDefault();
    stripe.createToken(card).then(function(result) {
      if (result.error) {
        // Inform the user if there was an error.
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
        errorElement.style.display = 'block';
      } else {
        // Send the token to your server.
        stripeTokenHandler(result.token);
      }
    });
  });
};
// Submit the form with the token ID.
function stripeTokenHandler(token) {
  // Insert the token ID into the form so it gets submitted to the server
  var form = document.getElementById('payment-form');

  var hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);

  var hiddenInput2 = document.createElement('input');
  hiddenInput2.setAttribute('type', 'hidden');
  hiddenInput2.setAttribute('name', 'subscriber[credit_suffix]');
  hiddenInput2.setAttribute('value', token.card.last4);

  form.appendChild(hiddenInput);
  form.appendChild(hiddenInput2);
  // Submit the form
  form.submit();
};
if ($('#card-element').length > 0) {
  setupStripeForm();
};

$('.js-payinfo-radio').click(function(event) {
  if ($('#pay_info_new_source').prop('checked')) {
    $('.js-radio-hidden-content').css('display', 'block');
  } else {
    $('.js-radio-hidden-content').css('display', 'none');
  }
});
$('.js-require-form .required').on('input', function(e) {
  if ($(this).val()) {
    $(this).next().css('display', 'none');
    $(this).removeClass('noinput');
    if (!$('.noinput').length) {
      $('.js-require-form button').prop('disabled', '');
    }
  } else {
    $('.js-require-form button').prop('disabled', 'disabled');
    $(this).addClass('noinput');
    $(this).next().css('display', 'block');
  }
});
$('.js-require-form .required').on('blur', function(e) {
  if ($(this).val()) {
    $(this).next().css('display', 'none');
  } else {
    $(this).next().css('display', 'block');
  }
});

var queryString = window.location.search;
if (queryString.indexOf('subscribe_modal=on') > 0 && $('.subscribe-area__button.modal-open-btn')) {
  $('.subscribe-area__button.modal-open-btn').click();
};
